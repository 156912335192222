import React from 'react'
import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class CtaForm extends React.Component {
  constructor(props) {
        super(props);
        this.state = {};
    }
    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": form.getAttribute("name"),
            ...this.state
          })
        })
          .then(() => navigate(form.getAttribute("action")))
          .catch(error => alert(error));
    };

   handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

   render () {

      return (
        <form id="leadership-guide-form" name="leadershipguide" method="POST" data-netlify="true" action="/thank-you-guide" netlify-honeypot="address" onSubmit={this.handleSubmit}>
          <p className="hide">
            <label>Don’t fill this out if you're human: <input name="address" onChange={this.handleChange} /></label>
          </p>
          <div className="field">
            <label className="label">Name:</label>
            <input className="input" type="text" required="required" name="name" onChange={this.handleChange}/>
          </div>
          <div className="field">
            <label className="label">Your Email:</label>
            <input className="input" type="email" name="email" required="required" onChange={this.handleChange}/>
          </div>
          <div className="field submit">
            <button type="submit" className="button is-fullwidth">Submit</button>
          </div>
        </form>
      )
  }
}

export default CtaForm
