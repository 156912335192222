import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {graphql, Link} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import Hero from '../components/Hero'
import CtaForm from '../components/CtaForm'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet
}) => {
  const PostContent = contentComponent || Content

  return (<section className="section">
    {helmet || ''}
    <div className="container content">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
            {title}
          </h1>
          <p>{description}</p>
          <PostContent content={content}/>
        </div>
      </div>
      <div className="blog-cta">
      <div className="columns">
        <div>
        <h2 className="is-size-4">Get Started</h2>
        <p> Ready to take your leadership to the next level?  Sign up below to get our free essential elements of leadership guide and get started on your leadership journey:</p>
        </div>
      </div>
      <div className="columns has-text-centered">
        <CtaForm />
      </div>
    </div>
    </div>
  </section>)
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet)
}

const BlogPost = ({data}) => {
  const {markdownRemark: post} = data

  return (<Layout>
    <Hero headerimg={post.frontmatter.image.childImageSharp.fluid}/>
    <BlogPostTemplate content={post.html} contentComponent={HTMLContent} description={post.frontmatter.description} helmet={<Helmet title = {
        `${post.frontmatter.title} | Blog`
      } />} tags={post.frontmatter.tags} title={post.frontmatter.title}/>
  </Layout>)
}
BlogPost.propTypes = {
  data: PropTypes.shape({markdownRemark: PropTypes.object})
}

export default BlogPost

export const pageQuery = graphql ` query BlogPostByID($id: String!) {
    markdownRemark(id : {
      eq: $id
    }) {
      id
      html
      frontmatter {
        date(formatString : "MMMM DD, YYYY")
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth : 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  `
